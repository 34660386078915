.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.login-right{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.login-lefth {
  background-image: linear-gradient(rgba(252, 5, 5, 0.707),  rgba(4, 4, 249, 0.984));
  /* background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.871) 30%,
  rgba(255, 0, 0, 0.708)  50%, rgba(17, 17, 235, 0.764) 20%); */
  min-height: 100vh;
  align-items: center;
  justify-content: center;  
}

.bg-navbar {
  background-image: linear-gradient(rgb(2, 2, 253),rgba(248, 91, 91, 0.779));
}

.bg-redbutton{
  background-image: linear-gradient( rgba(91, 126, 252, 0.565), rgba(249, 0, 0, 0.928));
}

.bg-greenbutton{
  background-image: linear-gradient( rgb(244, 236, 236), rgb(9, 204, 253));
}

.bg-greybutton{
  background-image: linear-gradient( rgba(27, 245, 7, 0.366), rgba(14, 15, 15, 0.295));
}

.tx-colorbutton{
  color: white !important;
}

.bgcardsale{
  background: linear-gradient(356deg, rgba(39, 189, 69, 0.413) 29%, rgba(231, 12, 12, 0.039) 80%, rgb(252, 253, 254) 93%);
}

.bgcardBuy{
  background: linear-gradient(356deg, rgba(189, 120, 35, 0.384) 29%, rgba(231, 12, 12, 0.039) 80%, rgb(252, 253, 254) 93%);
}

.bgcardUtility{
  background: linear-gradient(356deg, rgba(9, 135, 198, 0.502) 29%, rgba(231, 12, 12, 0.039) 80%, rgb(252, 253, 254) 93%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
